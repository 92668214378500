import {mapActions, mapGetters, mapMutations} from "vuex";
//sections
export default {
  name: "profile-favorites",
  components:{
  },
  data(){
    return{

    }
  },
  created() {

  },
  computed:{
    ...mapGetters({
      favoriteList: 'basket/favorites'
    }),
  },

  methods:{
    ...mapMutations({
      removeFavoriteProduct: `basket/REMOVE_ITEM_FROM_FAVORITES`
    }),
    removeFavorite(product){
      this.removeFavoriteProduct(product)
    }
  }
}